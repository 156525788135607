<template>
  <div>
    <GmapMap
        :center="{lat:9.1021, lng:18.2812}"
        :zoom="4"
        map-type-id="terrain"
        style="width: 100%; height: 800px"
    >
      <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="true"
          @click="center=m.position"
      />
    </GmapMap>
    <v-container>
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <h3>Our Address</h3>
          <p>JSB Konrete</p>
          <p>P.O. Box 6444 - 00800</p>
          <p>Utawala, Nairobi</p>
          <p>Kenya</p>
          <v-divider/>
          <h3>Our Contacts</h3>
          <p>Email: <a href="mailto:haronobetse5@gmail.com">info@jsbkonkrete.com</a></p>
          <p>Tel: +254721469955</p>
          <p>Tel: +254734412562</p>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <h3>Get In Touch</h3>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-text-field
                v-model="name"
                :counter="10"
                :rules="nameRules"
                label="Full Name"
                required
                outlined
                dense
            ></v-text-field>

            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                outlined
                dense
            ></v-text-field>

            <v-select
                v-model="select"
                :items="items"
                :rules="[v => !!v || 'Item is required']"
                label="Subject"
                required
                outlined
            ></v-select>

            <v-textarea
                v-model="select"
                :items="items"
                :rules="[v => !!v || 'Item is required']"
                label="Message"
                required
                outlined
            />

            <div class="g-recaptcha" data-sitekey="6LfYZdIgAAAAAECWI-5O-VxfTP_W0eeJV4hazFOi"></div>
            <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="validate"
            >
              Submit
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "ContactUsComponent",
  data: () => ({
    center: {lat: 9.1021, lng: 18.2812},
    valid: true,
    name: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    select: null,
    items: [
      'General Enquiry',
      'Product Enquiry',
      'Service Enquiry',
      'Other',
    ],
    checkbox: false,
  }),
  computed: {
    markers(){
      return {position: -1.045559}
    }
  },
  methods: {
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
  },
}
</script>

<style scoped>

</style>
