<template>
  <div>
    <v-card flat>
      <v-toolbar
          color="green darken-4"
          dark
          flat
          prominent
      >
        <div class="m-auto" style="margin: auto">
          <h1>WHAT WE DO</h1>
        </div>
      </v-toolbar>
    </v-card>
    <v-container>
      <v-card>
        <v-card-title class="text-center">
          JSB Konkrete
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <h1>Welcome to JSB Konkrete</h1>
              <v-list>
                <v-list-item class="ma-10">
                  1. Manufacturing & Distribution of Concrete Products and Supply of Aggregates
                  We offer various concrete products ranging from paving blocks, concrete blocks, precasts, and much more.</v-list-item>
                <hr/>
                <v-list-item class="ma-10"> 2. Leasing of Construction Equipment
                  Leasing of Construction Equipment i.e., Compactor machine, Poker machine and Concrete Mixer machine
                </v-list-item>
                <hr/>
                <v-list-item class="ma-10"> 3. Transportation of Materials
                  We have a fleet of trucks ranging from 1-ton to 30-ton loading capacity.
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6">
              <img src="@/assets/slide3.jpeg" alt="setup"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "TeamComponent",
  beforeRouteEnter(to,from,next){
    next(v=> {
      v.$store.dispatch('teamMembers')
    })
  },
  data: () => ({
    BASE_URL: process.env.VUE_APP_TEMP_BASE_URL,
    show: false,
    currentTab:0,
    tabs: null,
    tab: null,
    text:''
  }),
  computed: {
    teamMembers(){
      return this.$store.getters['helixGetter']('teamMembers');
    },
  }
}
</script>

<style scoped>

</style>
