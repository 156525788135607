<template>
  <div>
    <v-card flat>
      <v-toolbar
          color="green darken-4"
          dark
          flat
          prominent
      >
        <div class="m-auto" style="margin: auto">
          <h1>Focus Areas</h1>
        </div>

        <template v-slot:extension>
          <v-tabs
              v-model="tabs"
              centered
          >
            <v-tab
                v-for="(n,index) in focusAreas"
                :key="index"
            >
              {{ n.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tabs">
        <v-tab-item v-for="(item,index) in focusAreas" :key="index">
          <v-container>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-img height="300px" :src="BASE_URL+item['attachment_url']"></v-img>
                  </v-col>
                  <v-col cols="12" md="8">
                    <div class="mt-5">
                      <h3>{{item['title']}}</h3>
                      <p class="mt-5">{{item['description']}}</p>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-divider/>
<!--    <v-container>-->
<!--      <v-card max-height="300px">-->
<!--        <v-card-title class="text-center">-->
<!--          Featured Projects-->
<!--        </v-card-title>-->
<!--        <v-card-text>-->
<!--          <v-carousel :show-arrows="false" hide-delimiters max-height="300px">-->
<!--            <v-carousel-item-->
<!--                v-for="(item,i) in projects"-->
<!--                :key="i"-->
<!--                :src="BASE_URL+item.image_url"-->
<!--            >-->
<!--              <div class="ma-5 pa-5 text-center text-white">-->
<!--                <h1 class="text&#45;&#45;white" style="color:white; font-size:40px; font-weight:bolder">{{ item.name }}</h1>-->
<!--              </div>-->
<!--              <div class="text-center text-white">-->
<!--                <p style="color:white">{{ item.description }}</p>-->
<!--              </div>-->
<!--            </v-carousel-item>-->
<!--          </v-carousel>-->
<!--        </v-card-text>-->
<!--      </v-card>-->
<!--    </v-container>-->
  </div>
</template>

<script>
export default {
  name: "FocusAreaComponent",
  beforeRouteEnter(to,from,next){
    next(v=> {
      v.$store.dispatch('focusAreas')
      v.$store.dispatch('partners')
      v.$store.dispatch('projects')
    })
  },
  data: () => ({
    BASE_URL: process.env.VUE_APP_TEMP_BASE_URL,
    show: false,
    currentTab:0,
    tabs: null,
    tab: null,
    text:''
  }),
  computed: {
    partners(){
      return this.$store.getters['helixGetter']('partners');
    },
    projects(){
      return this.$store.getters['helixGetter']('projects');
    },
    focusAreas(){
      return this.$store.getters['helixGetter']('focusAreas');
    }
  }
}
</script>

<style scoped>

</style>
