<template>
  <div>
    <v-card flat>
      <v-toolbar
          color="green darken-4"
          dark
          flat
          prominent
      >
        <div class="m-auto" style="margin: auto">
          <h1>Meet Our Board</h1>
        </div>
      </v-toolbar>
    </v-card>
    <v-container>
      <v-row>
        <v-col v-bind:key="index" v-for="(item, index) in boardMembers" cols="12" md="3">
          <v-card
              class="mx-auto"
              max-width="100%"
              tile
              @click="$router.push({name: 'Profile'})"
          >
            <v-img
                height="100%"
                src="@/assets/logo.svg"
            >
              <v-row
                  align="end"
                  class="fill-height"
              >
                <v-col
                    align-self="start"
                    class="pa-0"
                    cols="12"
                >
                  <v-avatar
                      class="profile"
                      color="grey"
                      size="164"
                      tile
                  >
                    <v-img src="https://cdn.vuetifyjs.com/images/profiles/marcus.jpg"></v-img>
                  </v-avatar>
                </v-col>
                <v-col class="py-0">
                  <v-list-item
                      color="rgba(0, 0, 0, .4)"
                      dark
                  >
                    <v-list-item-content>
                      <p>{{item['designation']}} {{ item['first_name'] }} {{item['last_name']}}</p>
                      <v-divider/>
                      <p><small>{{item.role}}</small></p>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "BoardComponent",
  beforeRouteEnter(to,from,next){
    next(v=> {
      v.$store.dispatch('boardMembers')
    })
  },
  data: () => ({
    BASE_URL: process.env.VUE_APP_TEMP_BASE_URL,
    show: false,
    currentTab:0,
    tabs: null,
    tab: null,
    text:''
  }),
  computed: {
    boardMembers(){
      return this.$store.getters['helixGetter']('boardMembers');
    },
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
