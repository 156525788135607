<template>
  <div>
    <v-card flat>
      <v-toolbar
          color="green darken-4"
          dark
          flat
          prominent
      >
        <div class="m-auto" style="margin: auto">
          <h1>ABOUT JSB KONKRETE</h1>
        </div>

        <template v-slot:extension>
          <v-tabs
              v-model="tabs"
              centered
          >
            <v-tab
                v-for="(n,index) in services"
                :key="index"
            >
              {{ n.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
    </v-card>
    <v-divider/>
    <v-card>
      <v-card-title class="text-center">
        JSB Konkrete
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6" md="6" sm="12" lg="8">
            <img  src="@/assets/slide2.jpeg" alt="setup"/>
          </v-col>
          <v-col md="6" cols="6" sm="12" lg="4">
            <h1>Welcome to JSB Konkrete</h1>
            <p class="mt-10">

              JSB Konkrete Limited is based in Nairobi-Kenya and began its humble beginnings over 5 years ago. We are proud to be one of the largest privately-owned concrete products suppliers in Kenya. We manufacture and distribute concrete products and aggregates (ballast). We also run a fleet of trucks that offer transport services to sites whenever required.

              We understand that personal attention to each and every order, and each and every customer, help define great service. Small contractors and homeowners alike are treated with the same courtesy and attention to detail as our largest customers doing the largest pours. We wholeheartedly believe that our combined efforts will allow us to become a stronger, more efficient company. We are confident that this change will only strengthen our ability to provide consistent quality products and services to our valued customers.
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <hr/>
    <v-container>
      <v-card style="text-align: center">
        <h3> OUR MANAGEMENT</h3>
        <v-card-subtitle>Below is our team that ensures proper operations of JSB Konkrete</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <v-card
                  class="mx-auto"
                  max-width="344"
              >
                <v-img
                    src="@/assets/member1.jpeg"
                    height="400px"
                    cover
                ></v-img>

                <v-card-title>
                  HARON OBETSE
                </v-card-title>

                <v-card-subtitle>
                  Director
                </v-card-subtitle>
                <v-expand-transition>
                  <div v-show="show">
                    <v-divider></v-divider>

                    <v-card-text>
                      I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col md="6">
              <v-card
                  class="mx-auto"
                  max-width="344"
              >
                <v-img
                    src="@/assets/member2.jpeg"
                    height="400px"
                    cover
                ></v-img>

                <v-card-title>
                  ENOCK ACHAMI
                </v-card-title>

                <v-card-subtitle>
                  General Manager
                </v-card-subtitle>
                <v-expand-transition>
                  <div v-show="show">
                    <v-divider></v-divider>

                    <v-card-text>
                      I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ServiceComponent",
  beforeRouteEnter(to,from,next){
    next(v=> {
      v.$store.dispatch('services')
      v.$store.dispatch('projects')
    })
  },
  data: () => ({
    BASE_URL: process.env.VUE_APP_TEMP_BASE_URL,
    show: false,
    currentTab:0,
    tabs: null,
    tab: null,
    text:''
  }),
  computed: {
    services(){
      return this.$store.getters['helixGetter']('services');
    },
    projects(){
      return this.$store.getters['helixGetter']('projects');
    },
  }
}
</script>

<style scoped>

</style>
