<template>
  <div>
    <v-card flat>
      <v-toolbar
          color="green darken-4"
          dark
          flat
          prominent
      >
        <div class="m-auto" style="margin: auto">
          <h1>PRODUCTS</h1>
        </div>
      </v-toolbar>
    </v-card>
    <v-container>
      <v-card>
        <v-card-title class="text-center">
          JSB Konkrete Products
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-list>
                <v-list-item class="ma-10">
                  1. Cabros
                </v-list-item>
                <hr/>
                <v-list-item class="ma-10"> 2.Concrete Blocks
                </v-list-item>
                <hr/>
                <v-list-item class="ma-10"> 3. Paving Blocks</v-list-item>
                <hr/>
                <v-list-item class="ma-10"> 4. Concrete Precast</v-list-item>
                <hr/>
                <v-list-item class="ma-10"> 5. Construction Aggregates</v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6">
              <img src="@/assets/slide2.jpeg" alt="setup"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>

  </div>
</template>

<script>
import Mixin from "@/components/Mixin";

export default {
  name: "PublicationComponent",
  mixins: [ Mixin ],
  beforeRouteEnter(to, from, next) {
    next(v => {
      v.$store.dispatch('publications')
    })
  },
  data: () => ({
    BASE_URL: process.env.VUE_APP_TEMP_BASE_URL,
    show: false,
    currentTab: 0,
    tabs: null,
    tab: null,
    text: ''
  }),
  computed: {
    publications() {
      return this.$store.getters['helixGetter']('publications');
    },
  },
}
</script>

<style scoped>

</style>
